import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import classnames from 'classnames';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Collapse from '@frontend/ui-kit/Components/Collapse';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import MultipleChoice from '../MultipleChoice';
import EmptyData from '../../shared/EmptyData';
import withPopup from '../../../HOC/withPopup';
import {requestTimelineTemplateDeleting, requestTimelineTemplates} from '../../../actions/adminPortal';
import {redirectTo} from '../../../actions/general';
import {getMultipleChoiceValues} from '../../../selectors/adminPortal';
import {TIMELINE_TEMPLATE_STATUS_OPTIONS, TIMELINE_TEMPLATE_TYPES_OPTIONS} from '../../../options';
import {MULTIPLE_CHOICE_TYPES, ROUTES, TIMELINE_TEMPLATE_STATUSES, TIMELINE_TEMPLATE_TYPES} from '../../../constants';
import {getTimelineStepsWithUrl} from '../../../helpers';
import {equal, formatDate, getEqual, isEmpty} from '../../../utils';
import './index.scss';

const POPUP_ID = 'timelineTemplateDeletionPopup';
const DEFAULT_FILTERS = {status: TIMELINE_TEMPLATE_STATUSES.active};

const TimelineTemplatesLayout = ({openPopup, closePopup}) => {
    const dispatch = useDispatch();
    const [timelineTemplates, setTimelineTemplates] = useState([]);
    const chosenValues = useSelector(getMultipleChoiceValues) || {};
    const timelineStepsData = useMemo(() => getTimelineStepsWithUrl(), []);
    const inactiveTemplates = useMemo(() => timelineTemplates.filter(getEqual(TIMELINE_TEMPLATE_STATUSES.inactive, 'status')), [timelineTemplates]);
    const activeTemplates = useMemo(() => timelineTemplates.filter(getEqual(TIMELINE_TEMPLATE_STATUSES.active, 'status')), [timelineTemplates]);

    const requestTemplates = useCallback(async query => {
        const params = {...query, order_by: 'launch_date', direction: 'asc'};
        const {data: timelineTemplates, isSuccess} = await dispatch(requestTimelineTemplates(params));

        if (!isSuccess) {
            return false;
        }

        setTimelineTemplates(timelineTemplates);
    }, [dispatch]);

    const onCreateTemplate = useCallback(() => dispatch(redirectTo(ROUTES.createTimelineTemplate)), [dispatch]);

    useEffect(() => {
        requestTemplates(DEFAULT_FILTERS);
    }, [requestTemplates]);

    const onDeleteTemplate = id => {
        const onDelete = async () => {
            closePopup();
            await dispatch(requestTimelineTemplateDeleting(id));
            requestTemplates(chosenValues);
        };

        const actionBar = (
            <React.Fragment>
                <Button type={BUTTON_TYPES.secondary} onClick={closePopup}>Cancel</Button>
                <Button type={BUTTON_TYPES.destructive} onClick={onDelete}>Delete</Button>
            </React.Fragment>
        );
        const popupContent = <Text className='timeline-templates__deletion-popup'>Deleting this template means clients will not be able to select this launch date going forward until a new timeline template is generated for this launch date.</Text>;

        const popupProps = {title: 'Are you sure you want to delete this template?', actionBar, children: popupContent};
        const children = <PopupContent {...popupProps}/>;

        return openPopup({type: POPUP_TYPES.simple, children});
    };

    const getTemplateInitiator = ({id, type, status, launchDate}) => {
        const isRenewalType = equal(type, TIMELINE_TEMPLATE_TYPES.renewal);
        const onEdit = () => dispatch(redirectTo(`${ROUTES.timelineTemplates}/${id}`));

        const onDelete = event => {
            event.stopPropagation();
            onDeleteTemplate(id);
        };

        return (
            <Row className='initiator' middle='xs' between='xs' columnGap='xs'>
                <Column>
                    <div className={classnames('initiator__badge', {
                        initiator__badge_renewal: isRenewalType,
                        initiator__badge_inactive: equal(status, TIMELINE_TEMPLATE_STATUSES.inactive)
                    })}/>
                </Column>
                <Column className='initiator__type'>
                    <Text type={TEXT_TYPES.bodyBold}>{type}</Text>
                </Column>
                <Column xs className='initiator__dates'>
                    <div className='date'>
                        <Text className='date__title'>Launch Date</Text>
                        <Text type={TEXT_TYPES.bodyBold} className='date__value'>{launchDate}</Text>
                    </div>
                </Column>
                <Column className='initiator__action-bar'>
                    <Icon className='initiator__icon' type={ICON_TYPES.edit} onClick={onEdit}/>
                    <Icon className='initiator__icon' type={ICON_TYPES.delete} onClick={onDelete}/>
                </Column>
            </Row>
        );
    };

    const getTemplateContentStep = ({name, date}) => (
        <Column key={date} className='steps__column'>
            <Row middle='xs' between='xs'>
                <Column xs>
                    <Text>{name}</Text>
                </Column>
                <Column xs>
                    <div className='date'>
                        <Text className='date__title'>Due by</Text>
                        <Text type={TEXT_TYPES.bodyBold} className='date__value'>{date}</Text>
                    </div>
                </Column>
            </Row>
        </Column>
    );

    const getFormattedDate = date => formatDate(date, 'M/d/yyyy');

    const getTemplate = ({id, type, status, launch_date: launchDate, steps}) => {
        const formattedSteps = steps.map(({due_date, type}) => ({name: timelineStepsData[type]?.title, date: getFormattedDate(due_date)}));

        const collapseProps = {
            key: id,
            initiator: getTemplateInitiator({id, type, status, launchDate: getFormattedDate(launchDate)}),
            content: <Row className='steps'>{formattedSteps.map(getTemplateContentStep)}</Row>
        };

        return (
            <ContentSection className='template'>
                <Collapse {...collapseProps}/>
            </ContentSection>
        );
    };

    const multipleChoiceProps = {
        choices: [
            {name: 'Status', type: 'status', options: TIMELINE_TEMPLATE_STATUS_OPTIONS},
            {name: 'Launch Date', choiceType: MULTIPLE_CHOICE_TYPES.dateRange, type: 'launch_date'},
            {name: 'Type', type: 'type', options: TIMELINE_TEMPLATE_TYPES_OPTIONS}
        ],
        defaultValues: DEFAULT_FILTERS,
        onChange: requestTemplates
    };

    return (
        <div className='timeline-templates'>
            <Heading className='mb-10' type={HEADING_TYPES['1']}>Timeline</Heading>

            <div className='search'>
                <Row bottom='xs' columnGap='xs' between='xs'>
                    <Column xs>
                        <Heading className='search__title' type={HEADING_TYPES['4']}>Search</Heading>
                    </Column>
                    <Column constant>
                        <Button onClick={onCreateTemplate} className='search__button'>Create Custom Template</Button>
                    </Column>
                </Row>

                <ContentSection className='search__content-section'>
                    <MultipleChoice {...multipleChoiceProps}/>
                </ContentSection>
            </div>

            <div className='timeline-templates__list'>

                {!isEmpty(activeTemplates) && (
                    <React.Fragment>
                        <Heading type={HEADING_TYPES['4']}>Active Templates</Heading>
                        {activeTemplates.map(getTemplate)}
                    </React.Fragment>
                )}

                {isEmpty(activeTemplates) && !equal(chosenValues.status, TIMELINE_TEMPLATE_STATUSES.inactive) && <EmptyData title='There are currently no active templates!' description='At the moment there are no active templates, to create a new template, click "Create Custom Template" above. '/>}
            </div>

            {!isEmpty(inactiveTemplates) && (
                <div className='timeline-templates__list'>
                    <Heading type={HEADING_TYPES['4']}>Inactive Templates</Heading>

                    {inactiveTemplates.map(getTemplate)}
                </div>
            )}
        </div>
    );
};

TimelineTemplatesLayout.propTypes = {
    openPopup: PropTypes.func,
    closePopup: PropTypes.func
};

export {TimelineTemplatesLayout as TestableTimelineTemplatesLayout};
export default withPopup(POPUP_ID)(TimelineTemplatesLayout);
