import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Collapse from '@frontend/ui-kit/Components/Collapse';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Text from '@frontend/ui-kit/Components/Text';
import Separator from '@frontend/ui-kit/Components/Separator';
import Autocomplete from '@frontend/ui-kit/Components/Autocomplete';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Input from '@frontend/ui-kit/Components/Input';
import MultiSelectInput from '@frontend/ui-kit/Components/MultiSelectInput';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import Textarea from '@frontend/ui-kit/Components/Textarea';
import Sticker, {STICKER_TYPES} from '@frontend/ui-kit/Components/Sticker';
import {Field} from '../../../shared/FormComponents';
import useFieldArray from '../../../../hooks/useFieldArray';
import useForm from '../../../../hooks/useForm';
import {IMPORT_CONFIG_TYPES, TPA_GROUPS_FIELD_TYPES} from '../../../../constants';
import {equal} from '../../../../utils';

const JSON_FIELD_PLACEHOLDER = 'Add JSON values...';

const FIELDS_BY_TYPE = {
    [TPA_GROUPS_FIELD_TYPES.input]: Input,
    [TPA_GROUPS_FIELD_TYPES.multiSelectInput]: MultiSelectInput,
    [TPA_GROUPS_FIELD_TYPES.jsonInput]: ({...props}) => <Input {...props} placeholder={JSON_FIELD_PLACEHOLDER}/>,
    [TPA_GROUPS_FIELD_TYPES.checkbox]: ({label, ...props}) => <Checkbox {...props} caption={label}/>
};

const Tweak = ({tweaksName, index, allAdditionalFields, partnerConfig, groups, filterBy}) => {
    const form = useForm();
    const {fields} = useFieldArray(tweaksName);
    const field = `${tweaksName}[${index}]`;
    const {alias, isPending, ...restValues} = fields.value[index];
    const {title, group_launched: isLaunched} = groups.find(group => equal(group.alias, alias)) || {};
    const isVisible = isLaunched ? filterBy[IMPORT_CONFIG_TYPES.maintenance] : filterBy[IMPORT_CONFIG_TYPES.launch];

    const restFields = Object.keys(restValues);
    const additionalFields = Object.keys(allAdditionalFields).filter(additionalField => restFields.includes(additionalField));

    const onDeleteGroup = () => fields.remove(index);

    const additionalFieldsOptions = Object.entries(allAdditionalFields)
        .map(([key, {name}]) => ({value: key, label: name}))
        .filter(({value}) => !additionalFields.includes(value));

    const addAdditionalField = additionalField => {
        form.change(`${field}.${additionalField}`, partnerConfig[additionalField]);
    };

    const getAdditionalField = additionalField => {
        const {name, type} = allAdditionalFields[additionalField];
        const FieldComponent = FIELDS_BY_TYPE[type];

        return (
            <Column sm={6} key={additionalField}>
                <Field name={`${field}.${additionalField}`}>
                    {props => <FieldComponent {...props} label={name}/>}
                </Field>
            </Column>
        );
    };

    const pendingInitiator = (
        <div className='pending-header'>
            <Icon type={ICON_TYPES.alert}/>
            <Heading className='pending-header__alias' type={HEADING_TYPES['2']}>{title}:</Heading>
            <Sticker className='mr-10' type={STICKER_TYPES.default}>{isLaunched ? '🛠️ Maintenance' : '🚀 Launch'}</Sticker>
            <Text>Already has a pending validation report. To continue here will cancel the existing configuration and resubmit this one. If you would like to keep the existing config, please remove this group from your adjustments.</Text>
        </div>
    );

    const collapseProps = {
        key: alias,
        isOpened: true,
        hasCollapseIcon: true,
        initiator: isPending ? pendingInitiator : (
            <React.Fragment>
                <Heading type={HEADING_TYPES['2']}>{title}</Heading>
                <Sticker className='ml-10' type={STICKER_TYPES.default}>{isLaunched ? '🛠️ Maintenance' : '🚀 Launch'}</Sticker>
            </React.Fragment>
        ),
        content: (
            <React.Fragment>
                <Field name={`${field}.company_id`}>{props => <Input {...props} type='hidden'/>}</Field>

                <Row className='pt-10' rowGap='md'>
                    <Column sm={6}>
                        <Field name={`${field}.allowable_condition`}>
                            {props => <Input {...props} label='Allowable Condition' icon={ICON_TYPES.alertCircle} tooltipProps={{content: 'A single column can be listed here. Users with a populated value for that column will be imported.'}} description='Example: {"column_name_from_file" : "value"}' placeholder={JSON_FIELD_PLACEHOLDER}/>}
                        </Field>
                    </Column>

                    <Column sm={6}>
                        <Field name={`${field}.not_allowable_condition`}>
                            {props => <Input {...props} label='Not Allowable Condition' icon={ICON_TYPES.alertCircle} tooltipProps={{content: 'Multiple fields can be listed. Users who have a value associated with 1 or more of these fields will NOT be imported.'}} description='Example: [{"key1": "Value 1"}, {"key1": "Value 2"}]' placeholder={JSON_FIELD_PLACEHOLDER}/>}
                        </Field>
                    </Column>

                    <Column sm={6}>
                        <Field name={`${field}.allowable_condition_or`}>
                            {props => <Input {...props} label='Allowable Condition Or' icon={ICON_TYPES.alertCircle} tooltipProps={{content: 'Multiple fields can be listed. Users must have a value associated with 1 or more of these fields in order to be imported.'}} description='Example: [{"key1": "Value 1"}, {"key1": "Value 2"}]' placeholder={JSON_FIELD_PLACEHOLDER}/>}
                        </Field>
                    </Column>

                    <Column sm={6}>
                        <Field name={`${field}.not_empty_fields`}>
                            {props => <MultiSelectInput {...props} label='Not Empty Fields' icon={ICON_TYPES.alertCircle} tooltipProps={{content: 'A single column can be listed here. Users must have a populated value for that column in order to be imported.'}}/>}
                        </Field>
                    </Column>

                    <Column sm={6}>
                        <Field name={`${field}.not_empty_fields_or`}>
                            {props => <MultiSelectInput {...props} label='Not Empty Fields Or' icon={ICON_TYPES.alertCircle} tooltipProps={{content: 'Multiple fields can be listed. Users must have a value associated with at least 1 of the listed fields in order to be imported.'}}/>}
                        </Field>
                    </Column>

                    <Column sm={6}>
                        <Field name={`${field}.empty_fields`}>
                            {props => <MultiSelectInput {...props} label='Empty Fields' icon={ICON_TYPES.alertCircle} tooltipProps={{content: 'A single column can be listed here. Users must have and empty value associated with the column in order to be imported.'}}/>}
                        </Field>
                    </Column>

                    <Column sm={6}>
                        <Field name={`${field}.empty_fields_or`}>
                            {props => <MultiSelectInput {...props} label='Empty Fields Or' icon={ICON_TYPES.alertCircle} tooltipProps={{content: 'Multiple fields can be listed. Users must have an empty value associated at least 1 of the listed field in order to be imported.'}}/>}
                        </Field>
                    </Column>

                    <Column sm={6}>
                        <Field name={`${field}.ignore_members`}>
                            {props => <Textarea {...props} label='Ignore Members' icon={ICON_TYPES.info} tooltipProps={{content: 'Values listed here will NOT be processed.'}} description='Example: {"column_name_from_file" : "value"}' placeholder={JSON_FIELD_PLACEHOLDER}/>}
                        </Field>
                    </Column>
                </Row>

                <Separator/>

                <Heading className='mb-10' type={HEADING_TYPES['4']}>Add Additional Fields</Heading>

                <Row rowGap='md'>
                    {additionalFields.map(getAdditionalField)}
                </Row>

                <Row className='mt-10'>
                    <Column sm={6}>
                        <Autocomplete onChange={addAdditionalField} options={additionalFieldsOptions} wrapperClassName='add-field' placeholder='Search to add another field...'/>
                    </Column>
                </Row>

                <Separator/>

                <div className='action-bar'>
                    <Button type={BUTTON_TYPES.tertiary} onClick={onDeleteGroup}><Icon type={ICON_TYPES.delete}/>Remove Group Modification</Button>
                </div>
            </React.Fragment>
        )
    };

    return (
        <ContentSection key={alias} className={classnames('tweak', 'mt-20', {tweak_pending: isPending, tweak_hidden: !isVisible})}>
            <Collapse {...collapseProps}/>
        </ContentSection>
    );
};

Tweak.propTypes = {
    tweaksName: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    allAdditionalFields: PropTypes.shape({}),
    partnerConfig: PropTypes.shape({}),
    groups: PropTypes.arrayOf(PropTypes.shape({})),
    filterBy: PropTypes.shape({})
};

export {Tweak as TestableTweak};
export default React.memo(Tweak);
