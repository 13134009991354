import {MULTIPLE_CHOICE_DEFAULT_NAME} from '../constants';

export const getPlanManagerPlans = state => state.adminPortal.planManagerPlans;

export const getPlanManagerPlanVersions = state => state.adminPortal.planManagerPlanVersions;

export const getPlanManagerBenefitsCategories = state => state.adminPortal.planManagerBenefitsCategories;

export const getPlanManagerBenefitsOrdering = state => state.adminPortal.planManagerBenefitsOrdering;

export const getCarrierManagerCarriers = state => state.adminPortal.carrierManagerCarriers;

export const getMultipleChoiceChoices = (state, name = MULTIPLE_CHOICE_DEFAULT_NAME) => state.adminPortal.multipleChoice[name]?.choices || [];

export const getMultipleChoiceValues = (state, name = MULTIPLE_CHOICE_DEFAULT_NAME) => state.adminPortal.multipleChoice[name]?.values || [];
