import React from 'react';
import PropTypes from 'prop-types';
import Heading from '@frontend/ui-kit/Components/Heading';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Select from '@frontend/ui-kit/Components/Select';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import Input from '@frontend/ui-kit/Components/Input';
import Separator from '@frontend/ui-kit/Components/Separator';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import {Field, FieldArray} from '../../shared/FormComponents';
import {SURGERY_PLUS_ELIGIBILITY_OPTIONS} from '../../../options';
import useFormState from '../../../hooks/useFormState';
import {equal, formatDate, validateRequired} from '../../../utils';

const validate = values => {
    return {
        consult_fees: values.consult_fees?.map(consultFee => {
            const {segment} = consultFee;

            return {
                segment: validateRequired(segment)
            };
        })
    };
};

const SurgeryPlus = ({isEditDisabled}) => {
    const {values} = useFormState();

    return (
        <div className='app-configuration-content'>
            <ContentSection className='app-configuration-content__section'>
                <Heading className='app-configuration-content__title'>SurgeryPlus</Heading>

                <FieldArray name='consult_fees'>{({fields = []}) => (
                    <React.Fragment>
                        {fields.map((field, index) => {
                            const isLast = equal(index, fields.value.length - 1);

                            return (
                                <React.Fragment>
                                    <Row>
                                        <Column sm={4}>
                                            <Field name={`${field}.segment`}>
                                                {props => <Select {...props} options={SURGERY_PLUS_ELIGIBILITY_OPTIONS} disabled={isEditDisabled} label='Eligibility' isRequired/>}
                                            </Field>
                                        </Column>
                                        <Column sm={4}>
                                            <Input value={formatDate(values.start_date, 'M/d/yyyy')} placeholder='mm/dd/yyyy' description='This field is populated from Salesforce' disabled label='Effective Date'/>
                                        </Column>
                                        <Column sm={4}>
                                            <Input value={formatDate(values.end_date, 'M/d/yyyy')} placeholder='mm/dd/yyyy' description='This field is populated from Salesforce' disabled label='Expiration Date'/>
                                        </Column>
                                        <Column sm={4}>
                                            <Checkbox value={values.has_bariatric_coverage} caption='Allow bariatric support' disabled/>
                                        </Column>
                                    </Row>

                                    {!isLast && <Separator/>}
                                </React.Fragment>
                            );
                        })}
                    </React.Fragment>
                )}
                </FieldArray>
            </ContentSection>
        </div>
    );
};

SurgeryPlus.propTypes = {
    isEditDisabled: PropTypes.bool.isRequired
};

export {validate, SurgeryPlus as TestableSurgeryPlus};
export default React.memo(SurgeryPlus);
