import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {getSidebarMode} from '../../../selectors/sidebar';
import {changeSidebarMode} from '../../../actions/sidebar';
import {DASHBOARD_LAYOUT_SELECTOR, SIDEBAR_FULL_MODE, SIDEBAR_HALF_MODE} from '../../../constants';
import {equal} from '../../../utils';
import './index.scss';

const Layout = ({children}) => {
    const sidebarMode = useSelector(getSidebarMode);
    const dispatch = useDispatch();
    const layoutClassName = DASHBOARD_LAYOUT_SELECTOR.slice(1);

    const onClick = () => {
        const isSidebarFullMode = equal(sidebarMode, SIDEBAR_FULL_MODE);

        if (!isSidebarFullMode) {
            return false;
        }

        dispatch(changeSidebarMode(SIDEBAR_HALF_MODE));
    };

    return <div data-testid='dashboard-layout' className={layoutClassName} onClick={onClick}>{children}</div>;
};

Layout.propTypes = {
    children: PropTypes.node
};

export {Layout as TestableLayout};
export default React.memo(Layout);
