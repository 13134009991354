import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import RadioGroup from '@frontend/ui-kit/Components/RadioGroup';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import {Field} from '../../../shared/FormComponents';
import useForm from '../../../../hooks/useForm';
import useFormState from '../../../../hooks/useFormState';
import {ELIGIBILITY_SEGMENT_OPTIONS} from '../../../../options';
import {normalizeBoolean, validateRequired} from '../../../../utils';
import './index.scss';

const validate = values => {
    const {has_segment_all_members, has_segment_hdhp_only, has_segment_medical_plan_enrollment} = values;

    return {
        has_segment_hdhp_only: !has_segment_all_members && !has_segment_medical_plan_enrollment ? validateRequired(has_segment_hdhp_only) : undefined,
        has_segment_medical_plan_enrollment: !has_segment_all_members && !has_segment_hdhp_only ? validateRequired(has_segment_medical_plan_enrollment) : undefined
    };
};

const TeladocEligibilitySegments = ({isEditDisabled}) => {
    const form = useForm();
    const {values} = useFormState();

    const onChangeAllMembersSegment = useCallback(() => {
        return form.batch(() => ['has_segment_hdhp_only', 'has_segment_medical_plan_enrollment'].forEach(field => form.change(field, false)));
    }, [form]);

    return (
        <div className='teladoc-eligibility-segments'>
            <Field name='has_segment_all_members' onChange={onChangeAllMembersSegment} parse={normalizeBoolean}>
                {props => <RadioGroup {...props} options={ELIGIBILITY_SEGMENT_OPTIONS} disabled={isEditDisabled}/>}
            </Field>

            <div className='teladoc-eligibility-segments__other-segments'>
                <Field name='has_segment_hdhp_only'>
                    {props => <Checkbox {...props} caption='HDHP Only' disabled={isEditDisabled || values.has_segment_all_members}/>}
                </Field>
                <Field name='has_segment_medical_plan_enrollment'>
                    {props => <Checkbox {...props} caption='Medical Plan Enrollment' disabled={isEditDisabled || values.has_segment_all_members}/>}
                </Field>
            </div>
        </div>
    );
};

TeladocEligibilitySegments.propTypes = {
    isEditDisabled: PropTypes.bool.isRequired
};

export {validate, TeladocEligibilitySegments as TestableTeladocEligibilitySegments};
export default React.memo(TeladocEligibilitySegments);
