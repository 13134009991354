import React, {useEffect} from 'react';

const usePrevious = value => {
    const ref = React.useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
};

export default usePrevious;
