import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import Table, {ROW_HIGHLIGHT_TYPES} from '@frontend/ui-kit/Components/Table';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Sticker, {STICKER_TYPES} from '@frontend/ui-kit/Components/Sticker';
import Tooltip from '@frontend/ui-kit/Components/Tooltip';
import Text from '@frontend/ui-kit/Components/Text';
import MultipleChoice from '../MultipleChoice';
import {redirectTo, requestCompanies} from '../../../actions/general';
import {clearMultipleChoice, requestEligibilityImportSessions, requestEligibilityImportSessionUpdating} from '../../../actions/adminPortal';
import {getIsImportSessionThresholds} from '../../../helpers';
import {
    convertDateToTimeZone,
    getUTCDate,
    equal,
    formatDate,
    formatListByLocales,
    isEmpty,
    promisifyAsyncFunction
} from '../../../utils';
import {AUTOIMPORT_CREATED_BY, ELIGIBILITY_IMPORT_STATUSES, ROUTES} from '../../../constants';
import './index.scss';

const TABLE_PAGE_SIZE = 20;

const MULTIPLE_CHOICE_NAME = 'autoImportPendingReview';

const AutoImportPendingReview = () => {
    const [table, setTable] = useState({unitCount: 0, pageIndex: 0});
    const [tableData, setTableData] = useState([]);
    const rowProps = useMemo(() => {
        return !isEmpty(tableData) && tableData.reduce((acc, {pending_review_is_visited: isVisited, id: sessionId}) => {
            return {...acc, ...!isVisited ? {[sessionId]: {highlightType: ROW_HIGHLIGHT_TYPES.danger}} : {}};
        }, {});
    }, [tableData]);
    const [search, setSearch] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(clearMultipleChoice());
        };
    }, []);

    const onFetchData = useCallback(async ({pageIndex = 0, pageSize = TABLE_PAGE_SIZE, ...search}) => {
        const query = {
            limit: pageSize,
            offset: pageIndex * pageSize,
            is_auto_import_pending_review: true,
            ...search
        };
        const {sessions, count, isSuccess} = await dispatch(requestEligibilityImportSessions(query));

        if (!isSuccess) {
            return false;
        }

        setTable({unitCount: count, pageIndex});
        setTableData(sessions);
    }, []);

    const onFetchTableData = useCallback(tableData => {
        onFetchData({...tableData, ...search});
    }, [search]);

    const loadCompanyOptions = promisifyAsyncFunction(async (query, value) => {
        const {companies} = await dispatch(requestCompanies(query));

        return companies.map(company => ({label: company[value], value: company[value]}));
    });

    const pendingReviewFilteringChoices = [
        {name: 'Company', type: 'company_name', loadOptions: query => loadCompanyOptions(query, 'name')},
        {name: 'Alias', type: 'company_alias', loadOptions: query => loadCompanyOptions(query, 'alias')}
    ];

    const getTableColumns = () => {
        const getDate = ({value: date}) => date && `${formatDate(convertDateToTimeZone(getUTCDate(date)), 'MM/dd/yyyy H:mm')} CST`;

        const getAction = ({cell, value: status}) => {
            const {id: importId, references, has_blockers: isBlockersErrors, company_alias: companyAlias, additional_data: additionalData} = cell?.row?.original || {};
            const isThresholds = getIsImportSessionThresholds(cell?.row?.original);
            const isThresholdsResolved = isThresholds ? equal(status, ELIGIBILITY_IMPORT_STATUSES.success) : (!isEmpty(references) && getIsImportSessionThresholds(references[0]));
            const isBlockersResolved = !isBlockersErrors && !isEmpty(references) && references[0].has_blockers;

            const onReview = async () => {
                const {isSuccess} = await dispatch(requestEligibilityImportSessionUpdating(importId, {pending_review_is_visited: true}));

                if (!isSuccess) {
                    return;
                }

                const route = (isThresholds && !isThresholdsResolved)
                    ? `${ROUTES.importsIndividual}/${companyAlias}/validation_report/${importId}?group_name=${additionalData?.company_name}#thresholds`
                    : `${ROUTES.importsIndividual}/${companyAlias}/blocker_errors_report/${importId}?group_name=${additionalData?.company_name}`;
                dispatch(redirectTo(route));
            };

            if (equal(status, ELIGIBILITY_IMPORT_STATUSES.failed)) {
                return (
                    <div className='failed'>
                        <Tooltip content={<span className='import-status-tooltip-content'>{cell?.row?.original?.failed_reason}</span>}><Icon className='mr-3' type={ICON_TYPES.info}/></Tooltip>
                        <div className='failed__content'>Failed</div>
                    </div>
                );
            }

            const errorsNamesList = [...isBlockersErrors ? ['Blockers'] : [], ...isThresholds ? ['Thresholds'] : []];
            const buttonErrorsText = formatListByLocales(errorsNamesList, {style: 'long', type: 'conjunction'});

            if (isBlockersResolved || isThresholdsResolved) {
                return (
                    <div className='status status_validate-file'>
                        <Icon type={ICON_TYPES.eligibility}/>
                        <div className='status__content'>
                            {isBlockersResolved && <Text className='import-resolved'>Blockers Resolved</Text>}
                            {isThresholdsResolved && <Text className='import-resolved'>Thresholds Resolved</Text>}
                            <Button data-testid='validation-report-button' onClick={onReview} type={BUTTON_TYPES.tertiary} className='status__button'>
                                {isBlockersResolved ? 'Review Thresholds' : 'Review Blockers'}
                            </Button>
                        </div>
                    </div>
                );
            }

            return buttonErrorsText && (
                <Button className='action-button' data-testid='action-button' type={BUTTON_TYPES.tertiaryWarning} onClick={onReview} iconLeft={<Icon className='mr-3' type={ICON_TYPES.eligibility}/>}>
                    Review {buttonErrorsText}
                </Button>
            );
        };

        const getCompany = ({value, cell}) => (
            <div>
                {!cell?.row?.original?.pending_review_is_visited && <Icon type={ICON_TYPES.circle} className='pending-review-not-visited mr-2'/>}
                {value}
            </div>
        );

        const getType = ({value, cell}) => {
            const {is_cobra: isCobra, additional_data: additionalData} = cell?.row?.original || {};
            const isAutoImport = equal(value, AUTOIMPORT_CREATED_BY);
            const title = isAutoImport ? 'Automated' : additionalData?.config_type;

            return (
                <Sticker className='type' type={STICKER_TYPES.default}>{isCobra && 'COBRA - '}{title}</Sticker>
            );
        };

        return [
            {Header: 'Date', accessor: 'created_at', Cell: getDate},
            {Header: 'Company', accessor: 'additional_data.company_name', Cell: getCompany},
            {Header: 'Alias', accessor: 'company_alias'},
            {Header: 'Type', accessor: 'additional_data.created_by', isSortable: false, Cell: getType, align: 'center'},
            {Header: 'Action', accessor: 'status', isSortable: false, Cell: getAction}
        ];
    };

    const tableProps = {
        data: tableData,
        pageSize: TABLE_PAGE_SIZE,
        isSortable: true,
        isFilterable: false,
        columns: getTableColumns(),
        className: 'mt-20',
        onFetchData: onFetchTableData,
        uniqueDataIdentifier: 'id',
        isCellTooltip: false,
        rowProps,
        ...table
    };

    return (
        <div className='auto-import-pending-review'>
            <MultipleChoice choices={pendingReviewFilteringChoices} onChange={setSearch} name={MULTIPLE_CHOICE_NAME}/>

            <Table {...tableProps}/>
        </div>
    );
};

export {AutoImportPendingReview as TestableAutoImportPendingReview};
export default React.memo(AutoImportPendingReview);
