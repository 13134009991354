import React from 'react';
import PropTypes from 'prop-types';
import Heading from '@frontend/ui-kit/Components/Heading';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Select from '@frontend/ui-kit/Components/Select';
import Separator from '@frontend/ui-kit/Components/Separator';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import Switcher from '@frontend/ui-kit/Components/Switcher';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Input from '@frontend/ui-kit/Components/Input';
import {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import {Field, FieldArray} from '../../shared/FormComponents';
import TeladocEligibilitySegments, {validate as validateEligibilitySegments} from './TeladocEligibilitySegments';
import {ELIGIBILITY_OPTIONS} from '../../../options';
import {TELADOC_ELIGIBILITY_TYPES} from '../../../constants';
import {formatDate, normalizePositiveNumber} from '../../../utils';
import useFormState from '../../../hooks/useFormState';
import './index.scss';

const validate = values => {
    const {separate_consult_fees_by_segment: isConsultFeeSeparatedBySegment} = values;

    return {
        ...(!isConsultFeeSeparatedBySegment && validateEligibilitySegments(values))
    };
};

const TeladocGeneralMedicalConfig = ({isEditDisabled}) => {
    const {values} = useFormState();
    const {separate_consult_fees_by_segment: isConsultFeeSeparatedBySegment} = values;

    return (
        <div className='app-configuration-content'>
            <ContentSection className='app-configuration-content__section'>
                <Heading className='app-configuration-content__title'>General Information</Heading>

                <Row className='mb-13'>
                    <Column sm={4}>
                        <Input value={formatDate(values.start_date, 'M/d/yyyy')} placeholder='mm/dd/yyyy' description='This field is populated from Salesforce' disabled label='Effective Date'/>
                    </Column>
                    <Column sm={4}>
                        <Input value={formatDate(values.end_date, 'M/d/yyyy')} placeholder='mm/dd/yyyy' description='This field is populated from Salesforce' disabled label='Expiration Date'/>
                    </Column>
                    <Column sm={4}>
                        <Field name='is_consult_fee_applied_to_deductible' className='checkbox-middle'>
                            {props => <Checkbox {...props} caption='Consult Fee Applied to Deductible' disabled={isEditDisabled || isConsultFeeSeparatedBySegment}/>}
                        </Field>
                    </Column>
                </Row>

                <Row className='mt-20'>
                    <Column sm={4}>
                        <Field name='has_dermatology_enabled'>
                            {props => <Switcher {...props} caption='Dermatology' disabled/>}
                        </Field>
                    </Column>
                    <Column sm={4}>
                        <Field name='has_nutrition_enabled'>
                            {props => <Switcher {...props} caption='Nutrition' disabled/>}
                        </Field>
                    </Column>
                </Row>

                {isConsultFeeSeparatedBySegment && (
                    <React.Fragment>
                        <Separator/>

                        <FieldArray name='consult_fees'>{({fields = []}) => (
                            <React.Fragment>
                                {fields.map((field, index) => {
                                    const consultFeePostfix = fields.value[index].segment === TELADOC_ELIGIBILITY_TYPES.HDHPOnly ? '- HSA Only' : '';

                                    return (
                                        <React.Fragment>
                                            <Row middle='xs' className='mb-13'>
                                                <Column sm={4}>
                                                    <Field name={`${field}.segment`}>
                                                        {props => <Select {...props} options={ELIGIBILITY_OPTIONS} disabled label='Eligibility' isRequired/>}
                                                    </Field>
                                                </Column>
                                                <Column sm={4}>
                                                    <Field name={`${field}.general_medical_consult_fee`} parse={normalizePositiveNumber}>
                                                        {props => <Input {...props} icon={ICON_TYPES.money} placeholder='' disabled label={`General Medical Consult Fee ${consultFeePostfix}`} isRequired/>}
                                                    </Field>
                                                </Column>
                                            </Row>

                                            <Row middle='xs' className='mb-13'>
                                                <Column sm={4}>
                                                    <Field name={`${field}.has_dermatology_enabled`}>
                                                        {props => <Switcher {...props} caption='Dermatology' disabled/>}
                                                    </Field>
                                                </Column>
                                                <Column sm={4}>
                                                    <Field name={`${field}.dermatology_consult_fee`} parse={normalizePositiveNumber}>
                                                        {props => <Input {...props} icon={ICON_TYPES.money} placeholder='' disabled label={`Dermatology Consult Fee ${consultFeePostfix}`} isRequired/>}
                                                    </Field>
                                                </Column>
                                            </Row>

                                            <Row middle='xs' className='mb-13'>
                                                <Column sm={4}>
                                                    <Field name={`${field}.has_nutrition_enabled`}>
                                                        {props => <Switcher {...props} caption='Nutrition' disabled/>}
                                                    </Field>
                                                </Column>
                                                <Column sm={4}>
                                                    <Field name={`${field}.nutrition_consult_fee`} parse={normalizePositiveNumber}>
                                                        {props => <Input {...props} icon={ICON_TYPES.money} placeholder='' disabled label={`Nutrition Consult Fee ${consultFeePostfix}`} isRequired/>}
                                                    </Field>
                                                </Column>
                                            </Row>

                                            {index + 1 < ELIGIBILITY_OPTIONS.length && <Separator/>}
                                        </React.Fragment>
                                    );
                                })}
                            </React.Fragment>
                        )}
                        </FieldArray>
                    </React.Fragment>
                )}

                {!isConsultFeeSeparatedBySegment && (
                    <React.Fragment>
                        <Row className='mt-20'>
                            <Column sm={4}>
                                <Field name='consult_fee.general_medical_consult_fee' parse={normalizePositiveNumber}>
                                    {props => <Input {...props} placeholder='$' disabled label='General Medical' description='This field is populated from Salesforce'/>}
                                </Field>
                            </Column>
                            <Column sm={4}>
                                <Field name='consult_fee.dermatology_consult_fee' parse={normalizePositiveNumber}>
                                    {props => <Input {...props} placeholder='$' disabled label='Dermatology' description='This field is populated from Salesforce'/>}
                                </Field>
                            </Column>
                            <Column sm={4}>
                                <Field name='consult_fee.nutrition_consult_fee' parse={normalizePositiveNumber}>
                                    {props => <Input {...props} placeholder='$' disabled label='Nutrition' description='This field is populated from Salesforce'/>}
                                </Field>
                            </Column>
                        </Row>

                        <Separator className='mt-20 mb-20'/>

                        <Heading className='mb-10'>Eligibility</Heading>
                        <TeladocEligibilitySegments isEditDisabled={isEditDisabled}/>
                    </React.Fragment>
                )}
            </ContentSection>
        </div>
    );
};

TeladocGeneralMedicalConfig.propTypes = {
    isEditDisabled: PropTypes.bool.isRequired
};

export {validate, TeladocGeneralMedicalConfig as TestableTeladocGeneralMedicalConfig};
export default React.memo(TeladocGeneralMedicalConfig);
