import {FORM_ERROR} from 'final-form';
import basicLifeCard from './static/images/cards/basic_life_card.svg';
import customCard from './static/images/cards/custom_card.svg';
import eapCard from './static/images/cards/eap_card.svg';
import fsaCard from './static/images/cards/fsa_card.svg';
import hraCard from './static/images/cards/hra_card.svg';
import hsaCard from './static/images/cards/hsa_card.svg';
import k401card from './static/images/cards/k401_card.svg';
import ltdCard from './static/images/cards/ltd_card.svg';
import stdCard from './static/images/cards/std_card.svg';
import volLifeCard from './static/images/cards/vol_life_card.svg';
import volLtdCard from './static/images/cards/vol_ltg_card.svg';
import volStdCard from './static/images/cards/vol_std_card.svg';
import wellnessCard from './static/images/cards/wellness_card.svg';
import benefitsAncillaryDetailsThumbnail from './static/images/thumbnails/benefits-ancillary-details.svg';
import benefitsAncillaryOverviewThumbnail from './static/images/thumbnails/benefits-ancillary-overview.svg';
import benefitsCoreDetailsThumbnail from './static/images/thumbnails/benefits-core-details.svg';
import benefitsCoreOverviewThumbnail from './static/images/thumbnails/benefits-core-overview.svg';
import companyInformationThumbnail from './static/images/thumbnails/company-information.svg';
import educationMarketingThumbnail from './static/images/thumbnails/education-marketing.svg';
import eligibilityDetailsThumbnail from './static/images/thumbnails/eligibility-details.svg';
import eligibilityOverviewThumbnail from './static/images/thumbnails/eligibility-overview.svg';
import reportingThumbnail from './static/images/thumbnails/reporting.svg';
import timelineThumbnail from './static/images/thumbnails/timeline.svg';
import mskImage from './static/images/broadcasterTemplates/msk.svg';
import hjEapImage from './static/images/broadcasterTemplates/hjEap.svg';
import openEnrollmentImage from './static/images/broadcasterTemplates/openEnrollment.svg';
import openEnrollmentMeetingImage from './static/images/broadcasterTemplates/openEnrollmentMeeting.svg';
import openEnrollmentPlanImage from './static/images/broadcasterTemplates/openEnrollmentPlan.svg';
import telemedImage from './static/images/broadcasterTemplates/telemed.svg';
import vaxTrackingImage from './static/images/broadcasterTemplates/vaxTracking.svg';

export const ROOT_ELEMENT = document.getElementById('root');

export const DASHBOARD_LAYOUT_SELECTOR = '.layout'; // FYI: In the dashboard, the main scrolling is happening in the layout. Please use this selector if you need to add a listener for the main scroll. (4.12.2020, Pasha)

const MULTI_ENV_PREFIX = window.location.hostname.match(/hj[0-9]{2}-(dev|stage)/)?.[0] || '';

export const {HJ_ENV: ENV = 'hj01-dev', SENTRY_DSN, GTM_ID, SEGMENT_KEY, ZENDESK_KEY, ZENDESK_HELP_CENTER_URL, JWT_ZENDESK_ACCESS_URL} = window.__RUNTIME_CONFIG__ || {};
export const [ENV_TYPE] = ENV.split('-').reverse();
export const IS_PRODUCTION = ENV_TYPE === 'prod';
export const HJ_CDN_BASE_URL = {
    'hj01-dev': 'https://cdn.hj01-dev.hjoy.net/api/v1',
    'hj01-stage': 'https://cdn.hj01-stage.hjoy.net/api/v1',
    'hj01-prod': 'https://cdn.healthjoy.com/api/v1'
}[ENV] || `https://cdn.${MULTI_ENV_PREFIX ? `${MULTI_ENV_PREFIX}` : 'hj01-dev'}.hjoy.net/api/v1`;

export const HJ_CDN_IMAGE_BASE_URL = {
    'hj01-dev': 'https://cdn.hj01-dev.hjoy.net/public/application',
    'hj01-stage': 'https://cdn.hj01-stage.hjoy.net/public/application',
    'hj01-prod': 'https://cdn.healthjoy.com/public/application'
}[ENV] || `https://cdn.${MULTI_ENV_PREFIX ? `${MULTI_ENV_PREFIX}` : 'hj01-dev'}.hjoy.net/public/application`;

export const HOUSTON_URL = IS_PRODUCTION
    ? 'https://houston.healthjoy.com'
    : `https://houston.${ENV || MULTI_ENV_PREFIX || 'hj01-dev'}.hjoy.net`;

export const CRM_URL = IS_PRODUCTION
    ? 'https://crm.healthjoy.com'
    : `https://crm.${ENV || MULTI_ENV_PREFIX || 'hj01-dev'}.hjoy.net`;

export const REDASH_URL = 'https://redash.hjoy.net';

export const POLICY_URLS = {
    termsAndConditions: 'https://www.healthjoy.com/client-terms-conditions',
    termsOfUse: 'https://www.healthjoy.com/terms-of-use',
    privacyPolicy: 'https://www.healthjoy.com/privacy-policy'
};

export const DEFAULT_SESSION_LENGTH = 10080; // FYI: minutes (21.04.2020, Oleh);
export const SESSION_LENGTH_RANGE = {from: 15, to: 129600};
export const PASSWORD_LENGTH_RANGE = {from: 8, to: 256};

export const CSS_TEAM_EMAIL = 'success@healthjoy.com';
export const CLIENT_MARKETING_GUIDE_URL = 'https://cdn.healthjoy.com/public/healthJoy_client_marketing_guide.pdf';
export const TELEMED_BLOG_URL = 'https://www.healthjoy.com/blog/benefits/telehealth/telemedicine-hdhp';
export const VIDEO_LIBRARY_URL = 'https://healthjoy.wistia.com/projects/m7cvtipfsn';

export const AUTHORIZATION_BASE_ROUTE = '/authorization';
export const ADMIN_BASE_ROUTE = '/admin_portal';
export const ERROR_BASE_ROUTE = '/error';
export const REPORTING_BASE_ROUTE = '/reporting';
export const BROADCASTER_BASE_ROUTE = '/broadcaster';
export const HOME_BASE_ROUTE = '/home';
export const CONTACTS_AND_COMPANY_BASE_ROUTE = '/contacts_and_company';
export const OVERVIEW_BASE_ROUTE = '/overview';
export const TERMS_BASE_ROUTE = '/terms';
export const ROUTES = {
    root: '/',
    adminPortalRoot: ADMIN_BASE_ROUTE,
    planManager: `${ADMIN_BASE_ROUTE}/plan_manager`,
    carrierManager: `${ADMIN_BASE_ROUTE}/carrier_manager`,
    createCarrier: `${ADMIN_BASE_ROUTE}/carrier_manager/create`,
    timelineTemplates: `${ADMIN_BASE_ROUTE}/timeline`,
    appConfiguration: `${ADMIN_BASE_ROUTE}/app_configurations`,
    createTimelineTemplate: `${ADMIN_BASE_ROUTE}/timeline/create`,
    recommendationsManager: `${ADMIN_BASE_ROUTE}/recommendations_manager`,
    createRecommendationCard: `${ADMIN_BASE_ROUTE}/recommendations_manager/create`,
    eligibilityImports: `${ADMIN_BASE_ROUTE}/eligibility_imports`,
    importsTpa: `${ADMIN_BASE_ROUTE}/eligibility_imports/tpa`,
    importsIndividual: `${ADMIN_BASE_ROUTE}/eligibility_imports/individual`,
    login: `${AUTHORIZATION_BASE_ROUTE}/login`,
    signOut: `${AUTHORIZATION_BASE_ROUTE}/sign_out`,
    locked: `${AUTHORIZATION_BASE_ROUTE}/locked`,
    activation: `${AUTHORIZATION_BASE_ROUTE}/activation`,
    password: `${AUTHORIZATION_BASE_ROUTE}/password`,
    passwordRecovery: `${AUTHORIZATION_BASE_ROUTE}/password_recovery`,
    verification: `${AUTHORIZATION_BASE_ROUTE}/verification`,
    resendCode: `${AUTHORIZATION_BASE_ROUTE}/resend_code`,
    addPhone: `${AUTHORIZATION_BASE_ROUTE}/add_phone`,
    timelineGeneration: `${HOME_BASE_ROUTE}/timeline_generation`,
    timeline: `${HOME_BASE_ROUTE}/timeline`,
    companyInfo: `${CONTACTS_AND_COMPANY_BASE_ROUTE}/company_information`,
    contactInfo: `${CONTACTS_AND_COMPANY_BASE_ROUTE}/contact_information`,
    baseContactInfo: `${CONTACTS_AND_COMPANY_BASE_ROUTE}/base_contact_information`,
    contacts: `${CONTACTS_AND_COMPANY_BASE_ROUTE}/contacts`,
    benefits: '/benefits',
    eligibility: '/eligibility',
    benefitsOverview: `${OVERVIEW_BASE_ROUTE}/benefits`,
    renewalOverview: `${OVERVIEW_BASE_ROUTE}/renewal`,
    openEnrollment: `${OVERVIEW_BASE_ROUTE}/open_enrollment`,
    summaryReporting: `${REPORTING_BASE_ROUTE}/summary`,
    programSavingsReporting: `${REPORTING_BASE_ROUTE}/program_savings`,
    utilizationReporting: `${REPORTING_BASE_ROUTE}/utilization`,
    memberActivationReporting: `${REPORTING_BASE_ROUTE}/member_activation`,
    reportDownloadsReporting: `${REPORTING_BASE_ROUTE}/download_reports`,
    lookerReporting: `${REPORTING_BASE_ROUTE}/looker`,
    lookerBrokerReporting: `${REPORTING_BASE_ROUTE}/looker_broker`,
    broadcasterWelcome: `${BROADCASTER_BASE_ROUTE}`,
    broadcastEditor: `${BROADCASTER_BASE_ROUTE}/broadcast_editor`,
    broadcastDetails: `${BROADCASTER_BASE_ROUTE}/broadcast_details`,
    privacyInfo: `${TERMS_BASE_ROUTE}/privacy`,
    legalInfo: `${TERMS_BASE_ROUTE}/legal`,
    pageNotFound: `${ERROR_BASE_ROUTE}/page_not_found`,
    serverError: `${ERROR_BASE_ROUTE}/server_error`,
    educationAndMarketing: '/education_and_marketing',
    pdfTemplate: '/pdf_template',
    externalRedirection: '/external_redirection'
};

export const FEATURE_FLAGS = {
    customRenewalThresholds: 'custom_renewal_thresholds',
    NewBroadcasterUI: 'NewBroadcasterUI'
};

export const WORKSPACES = {
    dashboard: 'dashboard',
    adminPortal: 'adminPortal'
};

export const SIDEBAR_EMPTY_MODE = -1;
export const SIDEBAR_HALF_MODE = 0;
export const SIDEBAR_FULL_MODE = 1;

export const JOB_TYPES = {enrollmentExport: 'export'};
export const JOB_STATUSES = {new: 'new', running: 'running', finished: 'finished', failed: 'failed'};

export const MIN_LENGTH_REGEXP = /^.{8,}$/g;
export const MAX_LENGTH_REGEXP = /^.{1,256}$/g;
export const UPPERCASE_REGEXP = /[A-Z]/g;
export const LOWERCASE_REGEXP = /[a-z]/g;
export const NUM_REGEXP = /[0-9]/g;
export const INT_REGEXP = /^[0-9]*$/;
export const ALPHABETIC_CHARACTER_REGEXP = /[A-z]/g;
export const ALPHANUMERIC_REGEXP = /^[a-z0-9]+$/i;
export const SPEC_CHARACTER_REGEXP = /[-\\[\]/{}()*+?!@#%&.^$_,'"‘±=~<>|]/g;
export const LETTERS_ROW_REGEXP = /^[A-z]*$/g;
export const NUMBERS_ROW_REGEXP = /^[0-9]*$/g;
export const PHONE_NUMBER_REGEXP = /\([0-9]{3}\)\s[0-9]{3}[-\s.][0-9]{4}$/;
export const EMAIL_REGEXP = /^[a-zA-Z0-9_!.#$%&'*+\-/=?^`{|}~]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/;
export const URL_REGEXP = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#()?&/=]*)$/;
export const SECURE_URL_REGEXP = /^https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#()?&/=]*)$/;
export const SFTP_USERNAME_REGEXP = /^[a-z0-9_-]+$/i;
export const SFTP_PATH_REGEXP = /^[\w\d]+:[\w\d]+@[\w\d.]+$/;
export const HOST_REGEXP = /^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/;

export const FILE_NAME_WITH_HASH = /^.*\_[a-zA-Z0-9]{6}\.[\w]{2,4}$/g;

export const LINE_BREAK_CHARACTER = '\n';

export const PASSWORD_RULES = {
    maxLength: MAX_LENGTH_REGEXP,
    uppercaseLetter: UPPERCASE_REGEXP,
    number: NUM_REGEXP,
    specCharacter: SPEC_CHARACTER_REGEXP
};

export const PASSWORD_STRENGTHS_RULES = {
    uppercaseLetter: UPPERCASE_REGEXP,
    number: NUM_REGEXP,
    specCharacter: SPEC_CHARACTER_REGEXP,
    lowercaseLetter: LOWERCASE_REGEXP,
    lettersRow: LETTERS_ROW_REGEXP,
    numbersRow: NUMBERS_ROW_REGEXP
};

export const HTTP_CODE_UNAUTHORIZED = 401;
export const HTTP_CODE_NOT_FOUND = 404;
export const HTTP_CODE_UNPROCESSABLE = 422;
export const SERVER_ERROR = 'SERVER_ERROR';
export const CANCEL_ERROR = 'CANCEL_ERROR';

export const ACCEPTABLE_TEXT_FORMATS = ['.pdf', '.csv', '.txt', '.xls', '.xlsx', '.doc', '.docx'];
export const MAX_TEXT_FILE_SIZE = 150;
export const ACCEPTABLE_IMAGE_FORMATS = ['.jpg', '.jpeg', '.png', '.gif', '.svg'];
export const MAX_IMAGE_SIZE = 5;

export const MAX_TPA_FILE_SIZE = 100;

export const REVERSED_UNITS = ['$'];

export const SORTING_ORDERS = {asc: 'asc', desc: 'desc'};

export const SIMPLE_ANSWERS = {yes: 'yes', no: 'no'};
export const OTHER = 'other';

export const TWO_STEP_CODE_CHANNELS = {sms: 'sms', call: 'call', email: 'email'};

export const STEP_ALIASES = {
    renewalOverview: '/renewal_overview',
    benefitsOverview: '/overview',
    kickoffCall: '/kickoff_call',
    gatheringBenefits: '/gathering_benefits',
    gatheringEligibility: '/gathering_eligibility',
    benefitsReview: '/benefits_review',
    eligibilityReview: '/eligibility_review',
    launchPreparation: '/launch_preparation'
};

export const LAUNCHED_TIMELINE_AVAILABILITY_PERIOD = 60;

export const TIMELINE_STEP_STATUSES = {
    started: 'started',
    completed: 'completed',
    incompleted: 'incompleted',
    new: 'new',
    goal: 'goal'
};

export const COMPANY_STAGES = {
    launch: 'launch',
    renewal: 'renewal',
    inService: 'in_service',
    churn: 'churn'
};

export const USER_ROLES = {
    admin: 'admin',
    contributor: 'contributor',
    viewer: 'viewer',
    zendesk: 'zendesk'
};

export const USER_ROLE_LABELS = {
    [USER_ROLES.admin]: 'Admin',
    [USER_ROLES.contributor]: 'Contributor',
    [USER_ROLES.viewer]: 'Viewer',
    [USER_ROLES.zendesk]: 'Help Center-Only Viewer'
};

export const USER_TYPES = {
    employee: 'employee',
    broker: 'broker',
    other: 'other',
    tpa: 'tpa'
};

export const USER_TYPE_LABELS = {
    [USER_TYPES.employee]: 'Employee',
    [USER_TYPES.broker]: 'Broker',
    [USER_TYPES.other]: 'Other',
    [USER_TYPES.tpa]: 'TPA'
};

export const GENERAL_ROLES = {
    admin: 'admin',
    regular: 'regular'
};

export const CORE_PLAN_TYPES = {
    medical: 'medical',
    dental: 'dental',
    vision: 'vision',
    additional: 'additional'
};

export const CORE_PLAN_LABELS_BY_TYPE = {
    [CORE_PLAN_TYPES.medical]: 'Medical Plan',
    [CORE_PLAN_TYPES.dental]: 'Dental Plan',
    [CORE_PLAN_TYPES.vision]: 'Vision Plan',
    [CORE_PLAN_TYPES.additional]: 'Additional Medical Plan Information'
};

export const PLAN_PERIOD_TYPES = {
    launch: 'launch',
    renewal: 'renewal',
    inService: 'in_service',
    past: 'past'
};

export const ANCILLARY_PLAN_TYPES = {
    basic: 'basic',
    hsa: 'hsa',
    hra: 'hra',
    fsa: 'fsa',
    life: 'life',
    ltd: 'ltd',
    std: 'std',
    voluntary_life: 'voluntary_life',
    benefit_guide: 'benefit_guide',
    critical_illness: 'critical_illness',
    cancer: 'cancer',
    hospital_indemnity: 'hospital_indemnity',
    whole_life: 'whole_life',
    k401: 'k401',
    universal_life: 'universal_life',
    eap: 'eap',
    medical_travel: 'medical_travel',
    prescription_program: 'prescription_program',
    wellness: 'wellness',
    custom: 'custom'
};

export const PLAN_ADVANCED_CUSTOMIZATION_WIDGET_TYPES = {
    text: 'text',
    numberedList: 'numbered_list',
    bulleted_list: 'bulleted_list',
    twoColumnTable: 'two_column_table',
    textTwoColumnTable: 'text_two_column_table'
};

export const PLAN_ADVANCED_CUSTOMIZATION_WIDGET_LABELS = {
    [PLAN_ADVANCED_CUSTOMIZATION_WIDGET_TYPES.text]: 'Paragraph',
    [PLAN_ADVANCED_CUSTOMIZATION_WIDGET_TYPES.numberedList]: 'Numbered List',
    [PLAN_ADVANCED_CUSTOMIZATION_WIDGET_TYPES.bulleted_list]: 'Bulleted List',
    [PLAN_ADVANCED_CUSTOMIZATION_WIDGET_TYPES.twoColumnTable]: 'Two-Column Table'
};

export const ELIGIBILITY_FILES_SOURCES = {
    upload: 'upload',
    sftp: 'sftp',
    employeeNavigator: 'employee_navigator'
};

export const ACCESS_RESTRICTION_TO_HJ_TYPES = {
    notAppear: 'not_appear',
    terminationDate: 'termination_date',
    other: 'other'
};

export const ANCILLARY_PLAN_CATEGORIES = {
    accident: 'accident',
    prescription_program: 'prescription_program',
    basicLife: 'basic_life',
    cancer: 'cancer',
    criticalIllness: 'critical_illness',
    eap: 'eap',
    hospitalIndemnity: 'hospital_indemnity',
    ltd: 'ltd',
    medicalTravel: 'medical_travel',
    std: 'std',
    supplementalLife: 'supplemental_life',
    supplementalLtd: 'supplemental_ltd',
    supplementalStd: 'supplemental_std',
    universalLife: 'universal_life',
    wellness: 'wellness',
    wholeLife: 'whole_life',
    k401: '401k',
    hsa: 'hsa',
    fsa: 'fsa',
    hra: 'hra',
    other: 'other',
    benefitGuide: 'benefit_guide'
};

export const ELIGIBILITY_FILES_CATEGORIES = {
    eligibility: 'eligibility_file',
    medicalIds: 'medical_ids_files',
    dental: 'dental_vision_ids_files',
    voluntary: 'voluntary_files',
    custom: 'additional_custom_files'
};

export const ELIGIBILITY_FILES_LABEL_BY_CATEGORY = {
    [ELIGIBILITY_FILES_CATEGORIES.eligibility]: 'Eligibility / Census File',
    [ELIGIBILITY_FILES_CATEGORIES.medicalIds]: 'Medical IDs',
    [ELIGIBILITY_FILES_CATEGORIES.dental]: 'Dental and Vision IDs',
    [ELIGIBILITY_FILES_CATEGORIES.voluntary]: 'Voluntary Elections',
    [ELIGIBILITY_FILES_CATEGORIES.custom]: 'Add Custom File'
};

export const MEDICAL_TRAIT_LABEL_BY_TYPE = {
    on_site_clinics: 'On-site clinics',
    in_house_pharmacies: 'In-house pharmacies',
    in_house_providers: 'In-house providers or facilities',
    direct_contracted_providers: 'Direct-contracted providers or facilities',
    multiple_networks: 'Multiple Medical Provider Networks',
    specialty_surgical_program: 'Specialty Surgical Program or Precertification',
    specialty_rx: 'Specialty Rx (example: International Mail Order)',
    reference_based_pricing_vendor: 'Reference-based Pricing',
    claims_analytics_platform: 'Claims Analytics Platform (e.g. Springbuk)',
    tpa_onboarding_worksheet: 'HealthJoy’s Member Services Onboarding Document',
    all_other_plan_documents: 'All Other Plan Documents'
};

export const ANCILLARY_PLANS = [
    {category: ANCILLARY_PLAN_CATEGORIES.accident, type: ANCILLARY_PLAN_TYPES.basic, label: 'Accident', card: customCard, priority: 1},
    {category: ANCILLARY_PLAN_CATEGORIES.prescription_program, type: ANCILLARY_PLAN_TYPES.prescription_program, label: 'Rx Program', card: customCard, priority: 3},
    {category: ANCILLARY_PLAN_CATEGORIES.basicLife, type: ANCILLARY_PLAN_TYPES.life, label: 'Basic Life', card: basicLifeCard, priority: 5},
    {category: ANCILLARY_PLAN_CATEGORIES.cancer, type: ANCILLARY_PLAN_TYPES.cancer, label: 'Cancer Indemnity', card: customCard, priority: 7},
    {category: ANCILLARY_PLAN_CATEGORIES.criticalIllness, type: ANCILLARY_PLAN_TYPES.critical_illness, label: 'Critical Illness', card: customCard, priority: 9},
    {category: ANCILLARY_PLAN_CATEGORIES.eap, type: ANCILLARY_PLAN_TYPES.eap, label: 'EAP', card: eapCard, priority: 11},
    {category: ANCILLARY_PLAN_CATEGORIES.hospitalIndemnity, type: ANCILLARY_PLAN_TYPES.hospital_indemnity, label: 'Hospital Indemnity', card: customCard, priority: 13},
    {category: ANCILLARY_PLAN_CATEGORIES.ltd, type: ANCILLARY_PLAN_TYPES.ltd, label: 'Long Term Disability (LTD)', card: ltdCard, priority: 15},
    {category: ANCILLARY_PLAN_CATEGORIES.medicalTravel, type: ANCILLARY_PLAN_TYPES.medical_travel, label: 'Medical Travel', card: customCard, priority: 17},
    {category: ANCILLARY_PLAN_CATEGORIES.std, type: ANCILLARY_PLAN_TYPES.std, label: 'Short Term Disability', card: stdCard, priority: 19},
    {category: ANCILLARY_PLAN_CATEGORIES.supplementalLife, type: ANCILLARY_PLAN_TYPES.voluntary_life, label: 'Voluntary Life', card: volLifeCard, priority: 21},
    {category: ANCILLARY_PLAN_CATEGORIES.supplementalLtd, type: ANCILLARY_PLAN_TYPES.ltd, label: 'Voluntary LTD', card: volLtdCard, priority: 2},
    {category: ANCILLARY_PLAN_CATEGORIES.supplementalStd, type: ANCILLARY_PLAN_TYPES.std, label: 'Voluntary Short Term Disability', card: volStdCard, priority: 4},
    {category: ANCILLARY_PLAN_CATEGORIES.universalLife, type: ANCILLARY_PLAN_TYPES.universal_life, label: 'Universal Life', card: customCard, priority: 6},
    {category: ANCILLARY_PLAN_CATEGORIES.wellness, type: ANCILLARY_PLAN_TYPES.wellness, label: 'Wellness', card: wellnessCard, priority: 8},
    {category: ANCILLARY_PLAN_CATEGORIES.wholeLife, type: ANCILLARY_PLAN_TYPES.whole_life, label: 'Whole Life', card: customCard, priority: 10},
    {category: ANCILLARY_PLAN_CATEGORIES.k401, type: ANCILLARY_PLAN_TYPES.k401, label: '401k', card: k401card, priority: 12},
    {category: ANCILLARY_PLAN_CATEGORIES.hsa, type: ANCILLARY_PLAN_TYPES.hsa, label: 'HSA', card: hsaCard, priority: 14},
    {category: ANCILLARY_PLAN_CATEGORIES.fsa, type: ANCILLARY_PLAN_TYPES.fsa, label: 'FSA', card: fsaCard, priority: 16},
    {category: ANCILLARY_PLAN_CATEGORIES.hra, type: ANCILLARY_PLAN_TYPES.hra, label: 'HRA', card: hraCard, priority: 18},
    {category: ANCILLARY_PLAN_CATEGORIES.other, type: ANCILLARY_PLAN_TYPES.custom, label: 'Other', card: customCard, priority: 20},
    {category: ANCILLARY_PLAN_CATEGORIES.benefitGuide, type: ANCILLARY_PLAN_TYPES.benefit_guide, label: 'Benefit Guidance', isHidden: true}
];

export const ANCILLARY_PLANS_DATA_BY_CATEGORY = ANCILLARY_PLANS.reduce((acc, {category, ...planData}) => ({...acc, [category]: planData}), {});

export const SSN = 'Social Security Number';

export const FIN_ACCOUNT_TYPES = {
    hsa: 'HSA',
    fsa: 'FSA',
    hra: 'HRA'
};

export const MEMBER_SERVICE_UTILIZATION_TYPES = {
    benefitsSupportUtilization: 'benefits_support_utilization',
    benefitsWalletUtilization: 'benefits_wallet_utilization',
    mbr: 'medical_bill_reviews',
    preCertificationSteerage: 'pre_certification_steerage',
    mskEnrollments: 'msk_program_enrollments',
    providerFacilityGuidance: 'provider_and_facility_guidance',
    rxSavingsReviews: 'prescription_savings_reviews',
    telemedConsults: 'telemedicine_consults',
    teladocHealthConsults: 'teladoc_health_consults',
    totalUtilization: 'total_concierge_and_telemedicine_utilization'
};

export const FORM_GLOBAL_ERROR = FORM_ERROR;

export const FORMS = {
    eligibilityAncillaryPlansInfo: 'eligibility_ancillary_plans_info',
    eligibilityCorePlansInfo: 'eligibility_core_plans_info',
    eligibilityDetails: 'eligibility_details',
    eligibilityInfo: 'eligibility_info',
    companyClasses: 'company_classes',
    benefitsPreview: 'benefits_preview',
    planStep: 'plan_step',
    medicalTraitsStep: 'medical_traits_step',
    ancillaryPlansOverview: 'ancillary_plans_overview',
    companyGeneralInfo: 'company_general_info',
    companyHealthjoyOptions: 'company_healthjoy_options',
    companyHealthPlan: 'company_health_plan',
    ancillaryPlanDetails: 'ancillary_plan_details',
    benefitGuide: 'benefit_guide',
    planDetails: 'plan_details',
    passwordRecovery: 'password_recovery',
    activation: 'activation',
    additionalPlanInfo: 'additional_plan_info',
    newContactInfo: 'new_contact_info',
    existedContactInfo: 'existed_contact_info',
    baseContactInfo: 'base_contact_info',
    login: 'login',
    locked: 'locked',
    verification: 'verification',
    resendCode: 'resend_code',
    timelinePreparing: 'timeline_preparing',
    adminInvitation: 'admin_invitation',
    openEnrollmentOverview: 'open_enrollment_overview',
    broadcastContentStep: 'broadcast_content_step',
    broadcastInformationStep: 'broadcast_information_step',
    conditionalVariables: 'conditional_variables',
    categoryAndTrackingInfo: 'category_and_tracking_info',
    planManagerDetails: 'plan_manager_details',
    carrierManagerDetails: 'carrier_manager_details',
    carrierManagerDetailsCreating: 'carrier_manager_details_creating',
    hjBenefit: 'hjBenefit',
    planBenefit: 'plan_benefit',
    createTimelineCalendar: 'createTimelineCalendar',
    consultFees: 'consultFees',
    appConfiguration: 'appConfiguration',
    SFTPHost: 'SFTPHost',
    SFTPWhitelist: 'SFTPWhitelist',
    SFTPNewCredentials: 'SFTPNewCredentials',
    createRecommendation: 'createRecommendation',
    uploadIndividualImport: 'uploadIndividualImport',
    uploadIndividualCobraImport: 'uploadIndividualCobraImport',
    editTerminationsThreshold: 'editTerminationsThreshold',
    importGroupConfig: 'importGroupConfig',
    importConfigAncillary: 'importConfigAncillary',
    uploadTPAFile: 'uploadTPAFile',
    configureTPAGroups: 'configureTPAGroups'
};

// HACK: there are forms which ignore 'Leaving popup'. Some forms don't need it.
export const LEAVING_IGNORING_FORMS = [FORMS.benefitsPreview];

export const REPORTING_PLAN_PERIODS = {year: 'year', all: 'all'};

export const WALLET_LINKS = {
    hjEAP: '/v2/wallet/hj_eap',
    covid19: '/v2/wallet/covid-19',
    telemed: '/v2/wallet/telemedicine',
    openEnrollment: '/v2/wallet/enrollment',
    msk: '/v2/wallet/msk'
};

export const BROADCAST_TEMPLATE_IDS = {
    hjEAP: 'hjEAP',
    telemed: 'telemed',
    openEnrollment: 'openEnrollment',
    openEnrollmentMeeting: 'openEnrollmentMeeting',
    openEnrollmentPlan: 'openEnrollmentPlan',
    msk: 'msk',
    covid19VaccinationTracking: 'covid19VaccinationTracking',
    covid19VaccinationUpload: 'covid19VaccinationUpload'
};

export const BROADCAST_TEMPLATE_TITLES = {
    [BROADCAST_TEMPLATE_IDS.hjEAP]: 'EAP Reminder',
    [BROADCAST_TEMPLATE_IDS.telemed]: 'Telemedicine Awareness',
    [BROADCAST_TEMPLATE_IDS.openEnrollment]: 'Open Enrollment Period Reminder',
    [BROADCAST_TEMPLATE_IDS.openEnrollmentMeeting]: 'Open Enrollment Meeting Information',
    [BROADCAST_TEMPLATE_IDS.openEnrollmentPlan]: 'Open Enrollment Plan Changes',
    [BROADCAST_TEMPLATE_IDS.msk]: 'Virtual MSK Reminder'
};

export const BROADCAST_TEMPLATE_IMAGES = {
    [BROADCAST_TEMPLATE_IDS.hjEAP]: hjEapImage,
    [BROADCAST_TEMPLATE_IDS.telemed]: telemedImage,
    [BROADCAST_TEMPLATE_IDS.openEnrollment]: openEnrollmentImage,
    [BROADCAST_TEMPLATE_IDS.openEnrollmentMeeting]: openEnrollmentMeetingImage,
    [BROADCAST_TEMPLATE_IDS.openEnrollmentPlan]: openEnrollmentPlanImage,
    [BROADCAST_TEMPLATE_IDS.msk]: mskImage,
    [BROADCAST_TEMPLATE_IDS.covid19VaccinationTracking]: vaxTrackingImage,
    [BROADCAST_TEMPLATE_IDS.covid19VaccinationUpload]: vaxTrackingImage
};

export const FAMILY_SEGMENTS = {primary: 'primary', primarySpouse: 'primary_spouse', all: 'all'};

export const BROADCAST_STATUSES = {
    scheduled: 'SCHEDULED',
    sending: 'SENDING',
    sent: 'SENT',
    canceled: 'CANCELED',
    failed: 'FAILED',
    pending: 'PENDING',
    drafting: 'DRAFTING', // TODO: remove once broadcasterv2 is released (14.05.2024, Oleh)
    draft: 'DRAFT'
};

export const BROADCASTER_LINK_TYPES = {wallet: 'wallet_link', web: 'web_link', file: 'file_link'};

export const SATISFACTION_REVIEWS_MIN_LIMIT = 10;
export const ROI_CHART_BREAKEVEN_POINT_LIMIT = 1;

// PDF Template
export const PDF_TEMPLATE_TYPES = {
    performanceReport: 'performance_report',
    mskReport: 'msk_report'
};

export const DATA_UNITS = {
    bytes: 'Bytes',
    kb: 'KB',
    mb: 'MB',
    gb: 'GB',
    tb: 'TB'
};

export const KB_SIZE = 1024;
export const MS_IN_SEC = 1000;

export const FILE_FORMATS = {
    pdf: 'pdf',
    csv: 'csv',
    tsv: 'tsv',
    xls: 'xls',
    xlsx: 'xlsx',
    numbers: 'numbers',
    png: 'png',
    jpg: 'jpg',
    jpeg: 'jpeg',
    icon: 'icon',
    heic: 'heic',
    tiff: 'tiff',
    psd: 'psd',
    txt: 'txt',
    pages: 'pages',
    doc: 'doc',
    docx: 'docx',
    rtf: 'rtf',
    heiv: 'heiv',
    mp4: 'mp4',
    m4v: 'm4v',
    mov: 'mov',
    ppt: 'ppt',
    pptx: 'pptx',
    xml: 'xml'
};

export const IS_IE_BROWSER = ['MSIE ', 'Trident/'].some(detectionItem => window.navigator.userAgent.includes(detectionItem));
export const IS_MS_BROWSER = ['MSIE ', 'Trident/', 'Edge/'].some(detectionItem => window.navigator.userAgent.includes(detectionItem));

export const IS_SAFARI_BROWSER = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);

export const CARD_SOURCES = {
    static: 'static',
    legacy: 'legacy',
    ancillary: 'ancillary',
    insurance: 'insurance',
    reward: 'reward'
};

export const VARIABLE_CONDITIONS_MATCHING = {
    exists: 'exists',
    notExists: 'not_exist'
};

export const VARIABLE_CONDITIONS_OPERATORS = {
    and: 'and',
    or: 'or'
};

export const AVAILABILITY_CONDITIONS_TYPE = {
    conditions: 'conditions',
    variable: 'variable'
};

export const DEFAULT_INTERNAL_MENU_INDEX = 0;

export const LOCAL_STORAGE_ITEMS = {
    userTraits: 'userTraits',
    isAnalyticsIdentified: 'isAnalyticsIdentified',
    startedEligibilityFileExportIds: 'startedEligibilityFileExportIds',
    reportingPlanPeriod: 'reportingPlanPeriod',
    broadcastTemplate: 'broadcastTemplate',
    twoStepToken: 'twoStepToken',
    passLength: 'passLength',
    twoStepCodeChannel: 'twoStepCodeChannel',
    isTwoStepRequired: 'isTwoStepRequired',
    recentlyViewedClients: 'recentlyViewedClients'
};

export const ADMIN_PORTAL_ROUTES = [
    ROUTES.planManager,
    ROUTES.carrierManager,
    ROUTES.timelineTemplates,
    ROUTES.appConfiguration,
    ROUTES.recommendationsManager,
    ROUTES.eligibilityImports
];

export const COOKIES_ITEMS = {
    accessToken: 'access_token',
    dashboardAccessToken: 'dashboard_access_token',
    dashboardRefreshToken: 'dashboard_refresh_token'
};

export const SESSION_STORAGE_ITEMS = {
    isInitialPageLoaded: 'isInitialPageLoaded'
};

export const HTTP_RESPONSE_TYPES = {
    blob: 'blob'
};

export const AUTHORIZATION_ERROR_TYPES = {
    notFound: 'not_found',
    wrongPassword: 'wrong_password',
    incorrectCode: 'incorrect_code',
    tokenExpired: 'token_expired',
    accessLocked: 'access_locked'
};

export const AUTHORIZATION_ERROR_MESSAGES_BY_REASON = {
    [AUTHORIZATION_ERROR_TYPES.notFound]: 'Email not recognized.',
    [AUTHORIZATION_ERROR_TYPES.wrongPassword]: 'Invalid password.',
    [AUTHORIZATION_ERROR_TYPES.incorrectCode]: 'Incorrect code.',
    [AUTHORIZATION_ERROR_TYPES.tokenExpired]: 'Token expired or not known.',
    [AUTHORIZATION_ERROR_TYPES.accessLocked]: 'Account is temporary locked.'
};

export const DEFAULT_PHONE_PLACEHOLDER = '(XXX) XXX-XXXX';

export const PLAN_MANAGER_PLAN_STATUSES = {
    lastMerged: 'last merged'
};

export const YOUTUBE_EMBED_URL = 'https://www.youtube.com/embed';

export const ONBOARDING_VIDEOS_INFO = {
    [`${ROUTES.benefits}/:planPeriodId/core_benefits`]: {
        tooltipType: 'benefits_core_overview',
        start: 0,
        videoId: '9r20Mc92NmU',
        videoTitle: 'Benefits Overview',
        videoDescription: 'Provide an overview of your core and ancillary benefits so we can start building out your HealthJoy instance.',
        thumbnail: benefitsCoreOverviewThumbnail
    },
    [`${ROUTES.benefits}/:planPeriodId/core_benefits_details`]: {
        tooltipType: 'benefits_core_details',
        start: 0,
        videoId: 'aeVwz73S9lY',
        videoTitle: 'Benefits Overview',
        videoDescription: 'Our concierge team needs details and documents about each of your benefits to appropriately support your employees.',
        thumbnail: benefitsCoreDetailsThumbnail
    },
    [`${ROUTES.benefits}/:planPeriodId/ancillary_benefits`]: {
        tooltipType: 'benefits_ancillary_overview',
        start: 0,
        videoId: 'QXJE-ZC_ers',
        videoTitle: 'Benefits Overview',
        videoDescription: 'Provide an overview of your core and ancillary benefits so we can start building out your HealthJoy instance.',
        thumbnail: benefitsAncillaryOverviewThumbnail
    },
    [`${ROUTES.benefits}/:planPeriodId/ancillary_benefits_details`]: {
        tooltipType: 'benefits_ancillary_details',
        start: 0,
        videoId: '_0Iv_mohnlk',
        videoTitle: 'Benefits Overview',
        videoDescription: 'HealthJoy\'s concierge team needs details and documents about each of your benefits to appropriately support your employees.',
        thumbnail: benefitsAncillaryDetailsThumbnail
    },
    [`${ROUTES.eligibility}/:planPeriodId/implementation`]: {
        tooltipType: 'eligibility_overview',
        start: 0,
        videoId: 'O09paSYYqQc',
        videoTitle: 'Eligibility',
        videoDescription: 'Provide us with some details about how you manage eligibility. We\'ll use this to personalize your employees\' app experience.',
        thumbnail: eligibilityOverviewThumbnail
    },
    [`${ROUTES.eligibility}/:planPeriodId/details`]: {
        tooltipType: 'eligibility_details',
        start: 0,
        videoId: 'YoV8nqPftmY',
        videoTitle: 'Eligibility',
        videoDescription: 'Provide us with some details about how you manage eligibility. We\'ll use this to personalize your employees\' app experience.',
        thumbnail: eligibilityDetailsThumbnail
    },
    [ROUTES.timeline]: {
        tooltipType: 'timeline',
        start: 0,
        videoId: 'vLv7GZ7-bpY',
        videoTitle: 'Timeline',
        videoDescription: 'Learn what we need by when in order to meet your target launch date.',
        thumbnail: timelineThumbnail
    },
    [ROUTES.timelineGeneration]: {
        tooltipType: 'timeline',
        start: 0,
        videoId: 'vLv7GZ7-bpY',
        videoTitle: 'Timeline',
        videoDescription: 'Learn what we need by when in order to meet your target launch date.',
        thumbnail: timelineThumbnail
    },
    [ROUTES.summaryReporting]: {
        tooltipType: 'reporting',
        start: 0,
        videoId: 'WqeLpKW0m5E',
        videoTitle: 'Reporting',
        videoDescription: 'Gain insight into how your employees are using HealthJoy.',
        thumbnail: reportingThumbnail
    },
    [ROUTES.programSavingsReporting]: {
        tooltipType: 'reporting',
        start: 0,
        videoId: 'WqeLpKW0m5E',
        videoTitle: 'Reporting',
        videoDescription: 'Gain insight into how your employees are using HealthJoy.',
        thumbnail: reportingThumbnail
    },
    [ROUTES.utilizationReporting]: {
        tooltipType: 'reporting',
        start: 0,
        videoId: 'WqeLpKW0m5E',
        videoTitle: 'Reporting',
        videoDescription: 'Gain insight into how your employees are using HealthJoy.',
        thumbnail: reportingThumbnail
    },
    [ROUTES.memberActivationReporting]: {
        tooltipType: 'reporting',
        start: 0,
        videoId: 'WqeLpKW0m5E',
        videoTitle: 'Reporting',
        videoDescription: 'Gain insight into how your employees are using HealthJoy.',
        thumbnail: reportingThumbnail
    },
    [ROUTES.reportDownloadsReporting]: {
        tooltipType: 'reporting',
        start: 0,
        videoId: 'WqeLpKW0m5E',
        videoTitle: 'Reporting',
        videoDescription: 'Gain insight into how your employees are using HealthJoy.',
        thumbnail: reportingThumbnail
    },
    // [ROUTES.contacts]: {
    //     tooltipType: 'contacts',
    //     start: 0,
    //     videoId: 'atfGosMcpno',
    //     videoTitle: 'Your Contacts',
    //     videoDescription: 'Invite others to manage and contribute to your dashboard instance.',
    //     thumbnail: contactsThumbnail
    // },
    [ROUTES.companyInfo]: {
        tooltipType: 'company_information',
        start: 0,
        videoId: 'atfGosMcpno',
        videoTitle: 'Company Info',
        videoDescription: 'Gain insight into how your employees are using HealthJoy.',
        thumbnail: companyInformationThumbnail
    },
    // [ROUTES.broadcasterWelcome]: {
    //     tooltipType: 'broadcaster',
    //     start: 0,
    //     videoId: '45OkNGneogI',
    //     videoTitle: 'Broadcaster',
    //     videoDescription: 'Send in-app messages to employees through the HealthJoy app notifying them about open enrollment, a new wellness initiative, and much more.',
    //     thumbnail: broadcasterThumbnail,
    //     isSkipOnLoad: true
    // },
    [`${ROUTES.educationAndMarketing}/:id`]: {
        tooltipType: 'education_marketing',
        start: 0,
        videoId: 'wUAbW6nsVaA',
        videoTitle: 'Marketing Materials',
        videoDescription: 'Gain insight into how your employees are using HealthJoy.',
        thumbnail: educationMarketingThumbnail
    }
};

export const BENEFITS_CATEGORIES = {
    notOrganizedBenefits: 'NOT Organized Benefits',
    providerDoctorVisits: 'Provider (Doctor) Visits'
};

export const STATES = [['AL', 'Alabama'], ['AK', 'Alaska'], ['AZ', 'Arizona'], ['AR', 'Arkansas'], ['CA', 'California'], ['CO', 'Colorado'], ['CT', 'Connecticut'], ['DE', 'Delaware'], ['DC', 'District of Columbia'], ['FL', 'Florida'], ['GA', 'Georgia'], ['HI', 'Hawaii'], ['ID', 'Idaho'], ['IL', 'Illinois'], ['IN', 'Indiana'], ['IA', 'Iowa'], ['KS', 'Kansas'], ['KY', 'Kentucky'], ['LA', 'Louisiana'], ['ME', 'Maine'], ['MD', 'Maryland'], ['MA', 'Massachusetts'], ['MI', 'Michigan'], ['MN', 'Minnesota'], ['MS', 'Mississippi'], ['MO', 'Missouri'], ['MT', 'Montana'], ['NE', 'Nebraska'], ['NV', 'Nevada'], ['NH', 'New Hampshire'], ['NJ', 'New Jersey'], ['NM', 'New Mexico'], ['NY', 'New York'], ['NC', 'North Carolina'], ['ND', 'North Dakota'], ['OH', 'Ohio'], ['OK', 'Oklahoma'], ['OR', 'Oregon'], ['PA', 'Pennsylvania'], ['RI', 'Rhode Island'], ['SC', 'South Carolina'], ['SD', 'South Dakota'], ['TN', 'Tennessee'], ['TX', 'Texas'], ['UT', 'Utah'], ['VT', 'Vermont'], ['VA', 'Virginia'], ['WA', 'Washington'], ['WV', 'West Virginia'], ['WI', 'Wisconsin'], ['WY', 'Wyoming']];

export const TIMELINE_TEMPLATE_TYPES = {
    implementation: 'implementation',
    renewal: 'renewal'
};

export const TIMELINE_TEMPLATE_STATUSES = {
    inactive: 'inactive',
    active: 'active'
};

export const TIMELINE_TEMPLATE_MILESTONES = {
    [TIMELINE_TEMPLATE_TYPES.implementation]: [
        'impl_overview',
        'impl_kickoff_call',
        'impl_gathering_benefits',
        'impl_gathering_eligibility',
        'impl_benefits_review',
        'impl_eligibility_review',
        'impl_launch_preparation'
    ],
    [TIMELINE_TEMPLATE_TYPES.renewal]: [
        'ren_renewal_overview',
        'ren_gathering_benefits',
        'ren_gathering_eligibility',
        'ren_benefits_review'
    ]
};

export const HJ_SFTP_HOST = {
    'hj01-dev': 'hj01-dev-eligibility-sftp.healthjoy.com',
    'hj01-stage': 'hj01-stage-eligibility-sftp.healthjoy.com',
    'hj01-prod': 'sftp.healthjoy.com'
}[ENV] || `${MULTI_ENV_PREFIX ? `${MULTI_ENV_PREFIX}` : 'hj01-dev'}-eligibility-sftp.healthjoy.com`;

export const SFTP_HOST_TYPES = {
    internal: 'internal',
    tpa: 'tpa',
    external: 'external'
};

export const PLATFORM_AUTOMATION_TYPES = {
    'SFTP Standard - Fully Automated': 'SFTP Standard - Fully Automated',
    'SFTP Standard - Semi-Automated': 'SFTP Standard - Semi-Automated',
    'SFTP Custom': 'SFTP Custom',
    'Non-SFTP Standard': 'Non-SFTP Standard',
    'Non-SFTP Custom': 'Non-SFTP Custom',
    'On Hold': 'On Hold'
};

export const FAMILY_MANAGEMENT_TYPES = {
    fullAccess: 'full_access',
    eligible: 'eligible',
    disabled: 'disabled'
};

export const SFTP_SETTINGS_TYPES = {
    memberEligibility: 'member_eligibility',
    financialEligibility: 'financial_eligibility',
    cobraEligibility: 'cobra_eligibility',
    reporting: 'reporting'
};

export const SFTP_USERNAME_MIN_LENGTH = 3;
export const SFTP_USERNAME_MAX_LENGTH = 100;

export const MULTIPLE_CHOICE_DEFAULT_NAME = 'default';

export const MULTIPLE_CHOICE_TYPES = {
    select: 'select',
    asyncAutocomplete: 'asyncAutocomplete',
    input: 'input',
    dateRange: 'dateRange',
    date: 'date'
};

export const PREVIEW_CONTEXT = 'adash';

export const APP_CONFIG_STATUSES = {
    active: 'active',
    pending: 'pending',
    inactive: 'inactive',
    terminated: 'terminated',
    completed: 'completed'
};

export const USA_TIMEZONES = {
    central: 'America/Chicago'
};

export const PLAN_SOURCE = {
    ancillary: 'ancillary',
    insurance: 'insurance'
};

export const TELADOC_ELIGIBILITY_TYPES = {
    allMembers: 'all_members',
    HDHPOnly: 'hdhp_only',
    medicalPlanEnrollment: 'medical_plan_enrollment'
};

export const RECOMMENDATION_CARD_ACTIONS = {
    customPage: 'custom_page',
    applicationFlow: 'application_flow'
};

export const RECOMMENDATION_ACTION_FLOWS = {
    findCarePrefilled: 'find_care_prefilled'
};

export const PREFILLED_FIND_CARE_ACTION_FLOW_TYPES = {
    procedure: 'procedure',
    specialty: 'specialty'
};

export const ELIGIBILITY_IMPORT_TABS_VALUES = {
    import_log: 'import_log',
    pending_terminations: 'pending_terminations',
    pending_migrations: 'pending_migrations',
    auto_import_pending_review: 'auto_import_pending_review'
};

export const ELIGIBILITY_IMPORT_STATUSES = {
    new: 'new',
    verification_report_generating: 'verification_report_generating',
    processing: 'processing',
    failed: 'failed',
    success: 'success',
    pending_confirmation: 'pending_confirmation',
    writing: 'writing',
    cancelled: 'cancelled',
    confirmed: 'confirmed'
};

export const IMPORT_CONFIG_TYPES = {
    tpa: 'tpa',
    launch: 'launch',
    maintenance: 'maintenance',
    renewal: 'renewal'
};

// FYI: Import config types for config versions from el_processing BE service (Pasha, 05.30.2024)
export const IMPORT_VERSIONS_CONFIG_TYPES = {
    'New Launch': 'New Launch',
    Maintenance: 'Maintenance',
    Renewal: 'Renewal'
};

export const TPA_PARTNER_IMPORT_SESSION_STATUSES = {
    new: 'new',
    preprocessing: 'preprocessing',
    pending_report_validation: 'pending_report_validation',
    finished: 'finished',
    running: 'running',
    failed: 'failed',
    success: 'success',
    canceled: 'canceled'
};

export const TPA_GROUPS_FIELD_TYPES = {
    input: 'input',
    multiSelectInput: 'multiSelectInput',
    jsonInput: 'jsonInput',
    checkbox: 'checkbox'
};

export const AUTOIMPORT_CREATED_BY = 'system';

export const ELIGIBILITY_IMPORT_MASS_MEMBERS_LABELS = {
    demographic: 'Demographic',
    medical_plans: 'Medical Plans',
    dental_plans: 'Dental Plans',
    vision_plans: 'Vision Plans',
    ancillary_plans: 'Ancillary Plans',
    new_employees: 'Mass Member Add',
    new_dependents: 'Mass Dependent Add'
};

export const LAST_IMPORT_SESSION_WRITING_POPUP_DATA = {
    content: 'An import for this group is currently being written to the database. This import cannot be interrupted. Please wait for the current import to finish. You may submit this upload after the current import is completed.'
};

export const IMPORT_SESSION_IN_PROGRESS_STATUSES = [ELIGIBILITY_IMPORT_STATUSES.pending_confirmation, ELIGIBILITY_IMPORT_STATUSES.processing, ELIGIBILITY_IMPORT_STATUSES.new];

export const LAST_IMPORT_SESSION_IN_PROGRESS_POPUP_DATA = {
    title: 'Confirmation Required',
    content: 'Are you sure you want to upload this file? There is already a file uploading and in progress for the same company.'
};

export const RELATIONSHIPS = {
    self: 'self',
    spouse: 'spouse',
    other: 'other',
    'dependent-child': 'dependent-child',
    'adult-child': 'adult-child'
};

export const JSON_RPC_RESULTS = {
    success: 'ok'
};

export const WYSIWYG_TOOLBAR_OPTIONS = {
    options: ['inline', 'list', 'link'],
    inline: {options: ['bold', 'italic', 'underline']},
    list: {options: ['ordered', 'unordered']},
    link: {options: ['link', 'unlink']}
};

export const BROADCAST_VERSIONS = {
    v1: 1,
    v2: 2
};
