import React from 'react';
import PropTypes from 'prop-types';
import Heading from '@frontend/ui-kit/Components/Heading';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Separator from '@frontend/ui-kit/Components/Separator';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Input from '@frontend/ui-kit/Components/Input';
import {Field} from '../../shared/FormComponents';
import TeladocEligibilitySegments, {validate as validateEligibilitySegments} from './TeladocEligibilitySegments';
import {formatDate, normalizePositiveNumber} from '../../../utils';
import useFormState from '../../../hooks/useFormState';
import './index.scss';

const validate = values => {
    const {separate_consult_fees_by_segment: isConsultFeeSeparatedBySegment} = values;

    return {
        ...(!isConsultFeeSeparatedBySegment && validateEligibilitySegments(values))
    };
};

const TeladocTherapy = ({isEditDisabled}) => {
    const {values} = useFormState();
    const {separate_consult_fees_by_segment: isConsultFeeSeparatedBySegment} = values;

    return (
        <div className='app-configuration-content'>
            <ContentSection className='app-configuration-content__section'>
                <Heading className='app-configuration-content__title'>General Information</Heading>

                <Row>
                    <Column sm={4}>
                        <Input value={formatDate(values.start_date, 'M/d/yyyy')} placeholder='mm/dd/yyyy' description='This field is populated from Salesforce' disabled label='Effective Date'/>
                    </Column>
                    <Column sm={4}>
                        <Input value={formatDate(values.end_date, 'M/d/yyyy')} placeholder='mm/dd/yyyy' description='This field is populated from Salesforce' disabled label='Expiration Date'/>
                    </Column>
                    <Column sm={4}>
                        <Field name='is_consult_fee_applied_to_deductible' className='checkbox-middle'>
                            {props => <Checkbox {...props} caption='Consult Fee Applied to Deductible' disabled={isEditDisabled || isConsultFeeSeparatedBySegment}/>}
                        </Field>
                    </Column>
                </Row>

                <Row middle='xs' className='mt-20'>
                    <Column sm={4}>
                        <Field name='consult_fee.member_consult_fee' parse={normalizePositiveNumber}>
                            {props => <Input {...props} placeholder='$' disabled label='Member Consult Fee' description='This field is populated from Salesforce'/>}
                        </Field>
                    </Column>
                </Row>

                <Separator className='mt-20 mb-20'/>

                <Heading className='mb-10'>Eligibility</Heading>
                <TeladocEligibilitySegments isEditDisabled={isEditDisabled}/>
            </ContentSection>
        </div>
    );
};

TeladocTherapy.propTypes = {
    isEditDisabled: PropTypes.bool.isRequired
};

export {validate, TeladocTherapy as TestableTeladocTherapy};
export default React.memo(TeladocTherapy);
